.App {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
}

.color-nav {
  background-color: #2d5f90;
}

.no-card-border {
  border: none;
}
